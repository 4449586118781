import BaseApi from '../BaseApi';

class JackpotApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
  }

  createJackpotWinner = ({ targetId, amount, jackpotId, winTime }) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/self_jackpot`,
    {
      target_id: targetId,
      amount,
      jackpot_id: jackpotId,
      win_time: winTime,
    },
  );
}

export default JackpotApi
