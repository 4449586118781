import React, { useEffect, useState, useMemo, useCallback } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SVGInline from "react-svg-inline";
import { Link } from "react-router-dom";

import useModel from "features/reports/model/useModel";
import Paginator from "components/Paginator";

import ReportsFilter from "./ReportsFilter";

import "./Reports.scss";

const b = block("reports");

const Reports = () => {
  const locale = useSelector((state) => state.locale.locale, shallowEqual);

  const model = useModel();

  const listElements = useMemo(
    () =>
      model.outputState.items.map((item) => (
        <div key={item.id} className={b("item")}>
          <div className={b("item-td")}>{item.cashierID}</div>
          <div className={b("item-td")}>{item.userID}</div>
          <div className={b("item-td")}>{item.date}</div>
          <div className={b("item-td")}>{item.amount}</div>
          <div className={b("item-td")}>{locale[item.type]}</div>
        </div>
      )) || null,
    [model.outputState.items, locale]
  );

  return (
    <div className={b()}>
      <div className={b("filters")}>
        <ReportsFilter filterState={model.inputState} />
      </div>
      <div className={b("list")}>
        <div className={b("item")}>
          <div className={b("item-td")}>{locale.cashier}</div>
          <div className={b("item-td")}>{locale.user}</div>
          <div className={b("item-td")}>{locale.date}</div>
          <div className={b("item-td")}>{locale.amount}</div>
          <div className={b("item-td")}>{locale.type}</div>
        </div>
        {listElements}
      </div>
      <div className={b("paginator")}>
        <Paginator
          pageCount={model.outputState.pageCount}
          currentPage={model.inputState.values.page}
          onPageClick={model.inputState.onPageChanged}
        />
      </div>
    </div>
  );
};

export default Reports;
