import React, { useCallback, useEffect, useMemo, useState } from "react";
import block from "bem-cn";
import { shallowEqual, useSelector } from "react-redux";

import Input from "components/Input";
import InputDate from "components/InputDate";
import Button from "components/Button";

import "./ReportsFilter.scss";

const b = block("reports-filter-mobile");

const ReportsFilter = ({ filterState }) => {
  const locale = useSelector((state) => state.locale.locale, shallowEqual);

  return (
    <form className={b()} onSubmit={filterState.onSubmit}>
      <div className={b("items")}>
        <div className={b("item")}>
          <span className={b("item-title")}>{locale.userID}</span>
          <div className={b("item-input")}>
            <Input
              type="text"
              value={filterState.values.userID}
              callBack={(e) => filterState.onChangeUserID(e.target.value)}
              placeholder={locale.userID}
            />
          </div>
        </div>
        <div className={b("item-dates-container")}>
          <span className={b("item-dates-title")}>{locale.from}</span>
          <span className={b("item-dates-title")}>{locale.to}</span>
          <div className={b("item-dates-input")}>
            <InputDate
              dateFormat="yyyy-MM-dd-HH:mm"
              selected={filterState.values.from}
              onChange={filterState.onChangeFrom}
            />
          </div>
          <div className={b("item-dates-input")}>
            <InputDate
              dateFormat="yyyy-MM-dd-HH:mm"
              selected={filterState.values.to}
              onChange={filterState.onChangeTo}
            />
          </div>
        </div>
      </div>

      <div className={b("button")}>
        <Button type="submit">
          <span className={b("button-text")}>{locale.show}</span>
        </Button>
      </div>
    </form>
  );
};

export default ReportsFilter;
