import React, { forwardRef } from "react";
import block from "bem-cn";
import DatePicker from "react-datepicker";

import SVG from "components/SVG";

import { formateDate } from "shared/helpers";

import calendarSVG from "./img/calendar.svg";
import arrowSVG from "./img/arrow.svg";

import "react-datepicker/dist/react-datepicker.css";

import "./InputDate.scss";

const b = block("input-date-mobile");

const InputDate = ({ dateFormat, selected, isValid, onChange, color }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b("custom-date-input")} onClick={onClick} ref={ref}>
      <span className={b("custom-date-input-value")}>{formateDate(value)}</span>
      <SVG
        className={b("custom-date-input-calendar")}
        svgProps={{ svg: calendarSVG }}
      />
      {/* <SVG
        className={b("custom-date-input-arrow")}
        svgProps={{ svg: arrowSVG }}
      /> */}
    </div>
  ));

  return (
    <div className={b({ valid: isValid, color })}>
      <DatePicker
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        customInput={<ExampleCustomInput />}
        showTimeInput
        showTimeSelect
      />
    </div>
  );
};

export default InputDate;
