import initialState from "./initial";
import { ReportsActionDataTypes } from "./types";

function reducer(state = initialState, action) {
  switch (action.type) {
    case ReportsActionDataTypes.ACTION_PROCESSING: {
      const actionProcessing =
        action.payload?.[ReportsActionDataTypes.ACTION_PROCESSING]
          ?.actionProcessing;

      if (actionProcessing !== undefined) {
        return { ...state, actionProcessing };
      }
      return state;
    }

    case ReportsActionDataTypes.GET_LIST: {
      const list = action.payload?.[ReportsActionDataTypes.GET_LIST].list;

      if (list !== undefined) {
        return {
          ...state,
          list,
        };
      }
      return state;
    }

    default:
      return state;
  }
}

export default reducer;
