import React, { useCallback, useEffect, useMemo, useState } from "react";
import block from "bem-cn";
import dayjs from "dayjs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actions as reportsActions } from "features/reports";

import { itemsOnPage } from "./data";
import { convertFilters } from "./convertFilters";

const useModel = () => {
  const dispatch = useDispatch();
  const { list /* , pages */ } = useSelector(
    (state) => state.reports,
    shallowEqual
  );

  const [values, setValues] = useState({
    from: new Date(dayjs().add(-1, "day").format("YYYY-MM-DDTHH:mm:ss")),
    to: new Date(dayjs().format("YYYY-MM-DDTHH:mm:ss")),
    userID: "",
    page: 0,
  });

  const onChangeFrom = useCallback(
    (date) => setValues((ps) => ({ ...ps, from: date })),
    []
  );
  const onChangeTo = useCallback(
    (date) => setValues((ps) => ({ ...ps, to: date })),
    []
  );
  const onChangeUserID = useCallback(
    (value) => setValues((ps) => ({ ...ps, userID: value })),
    []
  );
  const onPageChanged = useCallback(
    (newPage) =>
      setValues((ps) => ({
        ...ps,
        page: newPage,
      })),
    []
  );

  // если айтэмы приходят под конкретную страницу и число страниц определяется бэком
  // const pageCount = useMemo(() => pages, [pages]);
  // const items = useMemo(
  //   () =>
  //     filteredHistory.map((t, index) => ({
  //       ...t,
  //       key: `${Object.values(t).join('-')}_${index}_${page}`,
  //     })),
  //   [filteredHistory, page],
  // );

  // если айтэмы приходят для всех страниц

  const pageCount = useMemo(() => Math.ceil(list.length / itemsOnPage), [list]);
  const items = useMemo(
    () =>
      list
        .slice(
          values.page * itemsOnPage,
          values.page * itemsOnPage + itemsOnPage
        )
        .map((t, index) => ({
          ...t,
          key: `${Object.values(t).join("-")}_${index}_${values.page}`,
        })),
    [list, values.page]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        reportsActions.getReportsList(
          convertFilters({
            from: values.from,
            to: values.to,
            userID: values.userID,
            page: values.page,
          })
        )
      );
    },
    [values.from, values.to, values.userID, values.page, dispatch]
  );

  useEffect(() => {
    dispatch(
      reportsActions.getReportsList(
        convertFilters({
          from: values.from,
          to: values.to,
          userID: values.userID,
          page: values.page,
        })
      )
    );
  }, [values.page]);

  useEffect(() => {
    // защита от сильной связанности пагинатора с остальными фильтрами
    if (values.page > pageCount - 1 && pageCount - 1 >= 0)
      onPageChanged(pageCount - 1);
  }, [values.page, pageCount]);

  return useMemo(
    () => ({
      outputState: {
        items,
        pageCount,
      },
      inputState: {
        values,
        onChangeFrom,
        onChangeTo,
        onChangeUserID,
        onPageChanged,
        onSubmit,
      },
    }),
    [
      items,
      pageCount,
      values,
      onChangeFrom,
      onChangeTo,
      onChangeUserID,
      onPageChanged,
      onSubmit,
    ]
  );
};

export default useModel;
