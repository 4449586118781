/* eslint-disable max-len */
import dayjs from "dayjs";

export const convertFilters = ({ from, to, userID, page }) => {
  return {
    from_date: dayjs(from).format("YYYY-MM-DD HH:mm"),
    to_date: dayjs(to).format("YYYY-MM-DD HH:mm"),
    user_id: userID,
    page,
  };
};
