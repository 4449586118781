/* eslint-disable no-continue */
import React, { useState, useMemo, useEffect } from "react";
import block from "bem-cn";
import { shallowEqual, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import SVG from "components/SVG";

import ArrowSVG from "./img/arrow.svg";

import "./Paginator.scss";

const b = block("paginator-mobile");

const Paginator = ({ pageCount, currentPage, onPageClick }) => {
  const handlePageClick = (event) => {
    onPageClick(event.selected);
  };

  return (
    <>
      <ReactPaginate
        forcePage={currentPage}
        breakLabel="..."
        previousLabel={
          <div className={b("arrow-left-container")}>
            <SVG className={b("arrow-left")} svgProps={{ svg: ArrowSVG }} />
          </div>
        }
        nextLabel={
          <div className={b("arrow-right-container")}>
            <SVG className={b("arrow-right")} svgProps={{ svg: ArrowSVG }} />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={2}
        pageCount={pageCount || 1}
        renderOnZeroPageCount={/* null */ undefined}
        containerClassName={b()}
        pageClassName={b("page-item")}
        pageLinkClassName={b("page-link")}
        previousClassName={b("page-item")}
        previousLinkClassName={b("page-link")}
        nextClassName={b("page-item")}
        nextLinkClassName={b("page-link")}
        breakClassName={b("page-item")}
        breakLinkClassName={b("page-link")}
        activeClassName={b("active")}
      />
    </>
  );

  // const locale = useSelector((state) => state.locale.locale, shallowEqual);

  // const [isOpen, changeOpen] = useState(false);

  // // массив страниц с пропущенной активной страницей
  // const items = useMemo(() => {
  //   let result = [];
  //   for (let i = 0; i < pageCount; i += 1) {
  //     if (currentPage === i) continue;
  //     result.push(i);
  //   }
  //   return result;
  // }, [pageCount, currentPage]);

  // const itemsList = useMemo(
  //   () =>
  //     items.map((item) => (
  //       <li key={item} className={b("item")} onClick={() => onPageClick(item)}>
  //         <span className={b("item-value")}>{item + 1}</span>
  //       </li>
  //     )),
  //   [items, onPageClick]
  // );

  // return (
  //   <div className={b()}>
  //     <p className={b("title")}>{`${locale.page}:`}</p>
  //     <div
  //       className={b("main", { open: isOpen })}
  //       onClick={() => changeOpen(!isOpen)}
  //       onMouseLeave={() => changeOpen(false)}
  //     >
  //       <div className={b("item", { current: true })}>
  //         <span className={b("item-value", { current: true })}>
  //           {currentPage + 1}
  //         </span>
  //         <SVG className={b("item-arrow")} svgProps={{ svg: ArrowSVG }} />
  //       </div>
  //       {isOpen && <ul className={b("items")}>{itemsList}</ul>}
  //     </div>
  //   </div>
  // );
};

export default Paginator;
