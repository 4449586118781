export default [
  {
    textId: "cashRegister",
    items: [
      {
        textId: "createUser",
        route: "/user-create",
      },
      {
        textId: "balances",
        route: "/users-list",
      },
      {
        textId: "cashiersTransactions",
        route: "/total",
      },
      {
        textId: "reports",
        route: "/reports",
        acceptedRoles: [1],
      },
    ],
  },
  {
    textId: "partners",
    items: [
      {
        textId: "transactionHistory",
        route: "/transaction-history",
      },
    ],
  },
];
