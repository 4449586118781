import { addNotify } from "features/notify";

const actionTypes = {
  ACTION_PROCESSING: "jackpotWinner/ACTION_PROCESSING",
};

const createJackpotWinner = ({ targetId, amount, jackpotId, winTime }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const response = await api.jackpot.createJackpotWinner({ targetId, amount, jackpotId, winTime });

  if (response.success) {
    dispatch(addNotify(locale.defaultSuccess, "success"));
  } else {
    const errorMessage = locale[response.codeStatus] ?? response.errorMessage ?? locale.defaultMessage;
    dispatch(addNotify(errorMessage, "error"));
  }

  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
};

export { actionTypes, createJackpotWinner };
