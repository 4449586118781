import { addNotify } from "features/notify";

import { ReportsActionDataTypes } from "./types";

const setActionProcessing = (actionProcessing) => {
  return async (dispatch, getState, extra) => {
    dispatch({
      type: ReportsActionDataTypes.ACTION_PROCESSING,
      payload: {
        [ReportsActionDataTypes.ACTION_PROCESSING]: { actionProcessing },
      },
    });
  };
};

const getReportsList = (filtersData) => {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const locale = getState().locale.locale;

    dispatch(setActionProcessing(true));

    const response = await api.reports.getReports(filtersData);
    if (response.success) {
      dispatch({
        type: ReportsActionDataTypes.GET_LIST,
        payload: {
          [ReportsActionDataTypes.GET_LIST]: { list: response.data },
        },
      });
    } else {
      dispatch(addNotify(locale.networkError, "error"));
    }

    dispatch(setActionProcessing(false));
  };
};

export { getReportsList };
