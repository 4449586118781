import BaseApi from "../BaseApi";

import { ReportsConverter } from "./ReportsConverter";

class ReportsApi extends BaseApi {
  converter;

  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new ReportsConverter();
  }

  getReports = (formDate) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/cashier_transfers`,
      formDate,
      undefined,
      (d) => this.converter.convertReports(d)
    );
}

export default ReportsApi;
