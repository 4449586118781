import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux'
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';

import Input from 'components/Input';
import { actions } from '../redux';
// import searchSvg from './img/search.svg';
import calendarSVG from './img/calendar.svg';

import './Total.scss';
import { useMemo } from 'react';

const Total = () => {
  const b = block('total');
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const totalInfo = useSelector(state => state.total.totalInfo);
  const actionProcessing = useSelector(state => state.total.actionProcessing);
  const [fromDate, changeFromDate] = useState(dayjs(new Date()).add(-7, 'day').format('YYYY-MM-DD'));
  const [toDate, changeToDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const initialDateForTime = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    return date;
  }, []);
  const [fromTime, setFromTime] = useState(initialDateForTime);
  const [toTime, setToTime] = useState(initialDateForTime);

  const formattedDate = (date, time) => `${date} ${dayjs(time).format('HH:mm')}`;

  useEffect(() => {
    dispatch(actions.getTotal(
      formattedDate(fromDate, fromTime),
      formattedDate(toDate, toTime),
    ));
  }, [])

  const callBack = useCallback(() =>
    dispatch(actions.getTotal(
      formattedDate(fromDate, fromTime),
      formattedDate(toDate, toTime),
    )),
  [fromDate, toDate, dispatch, fromTime, toTime]);

  return <section className={b()}>
    <div className={b('title')}>{locale.login}</div>
    <div className={b('input-block')}>
      {`${locale.startDate}:`}
      <div className={b('date-wrapper')}>
        <Input value={fromDate} onChange={e => changeFromDate(e.currentTarget.value)} type="date"/>
        <SVGInline className={b('icon').toString()} svg={calendarSVG} />
      </div>
      <ReactDatePicker
        selected={fromTime}
        onChange={(date) => setFromTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        className={b('time-input')}
      />
    </div>
    <div className={b('input-block')}>
      {`${locale.endingDate}:`}
      <div className={b('date-wrapper')}>
        <Input value={toDate} onChange={e => changeToDate(e.currentTarget.value)} type="date" />
        <SVGInline className={b('icon').toString()} svg={calendarSVG} />
      </div>
      <ReactDatePicker
        selected={toTime}
        onChange={(date) => setToTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeFormat="HH:mm"
        dateFormat="HH:mm"
        className={b('time-input')}
      />
    </div>
    <div className={b('bottom')}>
      <div className={b('button', { isLoading: actionProcessing })} onClick={actionProcessing ? f => f : callBack}>
        {locale.display}
      </div>
    </div>
    <article className={b('result')}>
      <div className={b('result-header')}>
        <span>{locale.total}</span>
        <span>{locale.term}</span>
        <span>{locale.amount}</span>
      </div>
      <div className={b('row')}>
        <span></span>
        <span>{locale.total}</span>
        <span>{totalInfo.total}</span>
      </div>
      <div className={b('row')}>
        <span></span>
        <span>{locale.cashPayment}</span>
        <span>{totalInfo.credits}</span>
      </div>
      <div className={b('row')}>
        <span></span>
        <span>{locale.cashPayout}</span>
        <span>{totalInfo.debits}</span>
      </div>
    </article>
  </section>
}

export default Total;
