import AuthApi from "./AuthApi";
import UserApi from "./UserApi";
import TotalApi from "./TotalApi";
import TransactionApi from "./TransactionApi";
import JackpotApi from "./JackpotApi";
import ReportsApi from "./ReportsApi";

class Api {
  constructor(baseUrl = "") {
    this.auth = new AuthApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.total = new TotalApi(baseUrl);
    this.transaction = new TransactionApi(baseUrl);
    this.jackpot = new JackpotApi(baseUrl);
    this.reports = new ReportsApi(baseUrl);
  }
}

export default Api;
