import React, { useEffect, useState } from "react";
import block from "bem-cn";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { actions as authAction } from "./features/Auth";
import Notify from "features/notify/view";
import CreateUser from "./features/users/view/CreateUser";
import UsersList from "./features/users/view/UsersList";
import UserEdit from "./features/users/view/UserEdit";
import ChangePas from "./features/changePas";
import { Total } from "./features/Total";
import { Locale } from "features/locale";
import { Auth } from "features/Auth";
import TransactionHistory from "./features/transaction/view/TransactionHistory";
import Reports from "features/reports/view";

import Footer from "components/Footer";
import Header from "components/Header";
import Subheader from "components/Subheader";
import Menu from "components/Menu";

import "./App.scss";

const b = block("app");

const App = () => {
  const locale = useSelector((state) => state.locale.locale);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);
  return (
    <div className={b()}>
      {/* {isPlashkaOpen && (
            <div className={b('plashka')}>
              <img className={b('plashka-img')} src={linkImg} alt="" />
              <div className={b('plashka-close')} onClick={() => changeIsPlashkaOpen(false)} />
              <a href="https://telegram.im/@partnertn" className={b('plashka-link')} />
              <a href="https://wa.me/+79627848214" className={b('plashka-link')} />
            </div>
          )} */}

      <Notify />
      {isAuth && <Header />}
      {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />}
      {isAuth ? (
        <Switch>
          <Route
            exact
            key="/user-create"
            path="/user-create"
            component={CreateUser}
          />
          <Route exact key="/locale" path="/locale" component={Locale} />
          <Route
            exact
            key="/users-list"
            path="/users-list"
            component={UsersList}
          />
          <Route exact key="/" path="/" component={Menu} />
          <Route exact key="/total" path="/total" component={Total} />
          <Route
            exact
            key="/user-edit/:id"
            path="/user-edit/:id"
            component={UserEdit}
          />
          <Route
            exact
            key="/transaction-history"
            path="/transaction-history"
            component={TransactionHistory}
          />
          <Route
            exact
            key="/change-pas"
            path="/change-pas"
            component={ChangePas}
          />
          <Route exact key="/reports" path="/reports" component={Reports} />
        </Switch>
      ) : (
        <Auth />
      )}
      <Footer locale={locale} isAuth={isAuth} />
    </div>
  );
};

export default App;
