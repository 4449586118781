import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export class ReportsConverter {
  convertReports = (data) =>
    data.data.map((d) => ({
      cashierID: d.cashier_id,
      userID: d.user_id,
      date: d.date,
      amount: d.amount,
      type: d.is_top_up ? "topUp" : "withdrawal",
    }));
}
